/* ChatButton.css */

body.no-scroll {
    overflow: hidden;
}

.chat-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 500;
}

.chat-button {
    z-index: 800;
    background-color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.chat-button-icon {
    font-size: 24px;
    color: #555555;
    z-index: 800;
}

.chat-button:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
}

.chat-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 500;
}

.chat-window {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 500px;
    max-height: 600px; /* Set maximum height for the chat window */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
    padding: 20px;
    z-index: 800;
    display: flex;
    flex-direction: column;
}

.chat-window h3 {
    margin-top: 0;
}

.chat-window p {
}

.chat-window ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    overflow-y: auto; /* Makes the list scrollable */
}

.chat-window li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
}

.chat-window li:hover {
    background-color: #f1f1f1;
}

.chat-window-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    border: 1px solid var(--primary-color);
}

.profile-pic-large {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.chat-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 10px;
}

.chat-secondary-header {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 10px;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;
}

.chat-bubble {
    max-width: 80%;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    white-space: pre-wrap;
}

.chat-bubble.me {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
    margin-left: auto;
}

.chat-bubble.ai {
    background-color: white; /* White background for the bubble */
    border: 5px solid transparent; /* Create space for the gradient border */
    background-image: linear-gradient(white, white), /* Base background */
    linear-gradient(270deg, #4285F4, #34A853, #FBB605, #EA4335); /* Initial gradient */
    background-origin: border-box;
    background-clip: padding-box, border-box; /* Clip the background to show gradient border */
    align-self: flex-start; /* Align received messages to the left */
    animation: gradientMove 3s ease infinite; /* Apply the animation */
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.chat-input {
    display: flex;
    align-items: center;
    width: 100%;
}

.chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-right: 10px;
}

.chat-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #0056b3;
}