:root {
  --black: rgba(0, 0, 0, 1); /* You can keep this or adjust if needed */
  --body-16-font-family: "Inter", Helvetica;
  --body-16-font-size: 16px;
  --body-16-font-style: normal;
  --body-16-font-weight: 500;
  --body-16-letter-spacing: -0.32px;
  --body-16-line-height: 140%;
  --body-18-font-family: "Inter", Helvetica;
  --body-18-font-size: 18px;
  --body-18-font-style: normal;
  --body-18-font-weight: 500;
  --body-18-letter-spacing: -0.18px;
  --body-18-line-height: 140%;
  --dark-grey: rgba(100, 100, 120, 1); /* Darker grey for better contrast */
  --off-white: rgba(40, 40, 40, 1); /* Dark grey instead of light off-white */
  --shape-corner-extra-large: 28px;
  --white: rgba(255, 255, 255, 0.9); /* Slightly off-white for dark mode */
}

