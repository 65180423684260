.option-pill {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: default;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.option-pill:hover {
  transform: translateY(-3px) scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.option-pill-label {
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 20px 0 0 20px;
  border: 1px solid #bbb;
  border-right: 0px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.option-pill-select-container {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 0 20px 20px 0;
  overflow: hidden;
  cursor: pointer;
}

.option-pill-select-container:hover {
  background-color: #ddd;
}

.option-pill-select {
  border: none;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 10px;
}
