:root {
  --primary-color: #7300cc;
  --primary-color-text: #6237a0;
  --secondary-color: #deacf5;
  --background-main: whitesmoke;
  --background-content: #fff;

  --primary-color-dark: #7300cc;
  --primary-color-text-dark: #b498da;
  --secondary-color-dark: #9c6eb1;
  --background-main-dark: rgb(48, 48, 48);
  --background-content-dark: #000;
}
