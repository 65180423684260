@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Montserrat&family=Poppins:wght@300&family=Raleway:wght@300&display=swap");

.App {
  font-family: "Poppins", sans-serif;
  border: 1px solid transparent;
  height: auto;
  width: 100%;
}
#root {
  height: auto;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  /* for debug: */
  /* border: 1px solid red; */
}

html,
body {
  margin: 0;
  height: auto;
  background-color: whitesmoke;
}

h2 {
  font-size: 1em;
  background: linear-gradient(to right, #494969, #696989);
  -webkit-background-clip: text;
}

h3 {
  border-bottom: 1px solid #494969;
}

form {
  /* width: 50%; */
  border: none;
}

a {
  color: #5f5f79;
  text-decoration: none;
}

button {
  font-family: "Poppins", sans-serif;
}

.btn.btn-outline-white {
  color: white;
  color: white;
  border: 1px solid;
  border-radius: 20px 20px;
}

.btn.btn-outline-white:hover {
  border: 1px solid white;
  background: #6d6dff;
}

.btn.btn-outline-blue {
  color: #5e69fd;
  border: 1px solid;
  border-radius: 20px 20px;
}

.btn.btn-outline-blue:hover {
  border: 1px solid white;
  background: #5e69fd;
  color: white;
}

html {
  overflow-y: scroll;
}

.main-content-div {
  margin-left: 200px;
  background-color: #f5f5f5;
}

.hero {
  background: url("img/landing/hero-bg.svg") no-repeat center center;
  background-size: cover;
  min-height: 850px;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: 65px;
}

.contact {
  color: white;
}
.personal-insight-container,
.personality-navigator-container,
.instant-insight-container {
  margin-bottom: 150px;
}

section.personal-insight {
  background-color: #e7e3ff;
}

section.instant-insight {
  background-color: #d8e2ff;
}

.contact-container {
  background: url("img/landing/subscribe-bg.svg") no-repeat center center;
  background-size: cover;
  min-height: 450px;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 30px;
}

.subscribe {
  color: white;
}

.subscribe-container {
  background: url("img/landing/subscribe-bg.svg") no-repeat center center;
  background-size: cover;
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 30px;
}

.left-section-col img.shape.shape-main {
  width: 95% !important;
  z-index: 100;
  position: relative;
  width: 350px;
  top: 155px;
  left: 10px;
}

.left-section-col img.shape.shape-1 {
  position: absolute;
  left: 50px;
}

.left-section-col img.shape.shape-2 {
  z-index: 100;
  position: relative;
  top: 165px;
  left: 225px;
}

.right-section-col img.shape.shape-main {
  width: 95% !important;
  z-index: 100;
  position: relative;
  top: 155px;
  left: 40px;
}

.right-section-col img.shape.shape-1 {
  position: absolute;
  right: 50px;
}

.right-section-col img.shape.shape-2 {
  z-index: 100;
  position: relative;
  top: 165px;
  left: 40px;
}

.card-title {
  font-size: 1.5rem;
}

.card-text {
  font-size: 1rem;
}

.landing_header_menu a {
  color: slategrey;
  text-decoration: none;
  font-weight: 600;
}

.landing_header_menu a:hover {
  color: currentcolor;
  font-weight: 700;
  font-size: 17px;
}

/* Basic styling */
.animated_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list_item {
  opacity: 0; /* Hide all items initially */
  transform: translateY(20px); /* Move items down for animation effect */
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
  /* Adjust as needed */
}

.list_item.show {
  opacity: 1; /* Show the item */
  transform: translateY(0); /* Move the item to its original position */
  animation: typing 1s steps(20, end) forwards;
}

/* Optional: Animation for hiding */
.list_item.hide {
  opacity: 0; /* Hide the item */
  display: none;
  transform: translateY(-20px); /* Move the item up */
}

::placeholder {
  color: #a9a9a9; /* This is a medium grey color */
  opacity: 1; /* Ensures the color is fully applied */
}

/* For webkit browsers (Chrome, Safari, etc.) */
::-webkit-input-placeholder {
  color: #a9a9a9;
}

/* For Firefox */
::-moz-placeholder {
  color: #a9a9a9;
}

/* For IE */
:-ms-input-placeholder {
  color: #a9a9a9;
}

/* For older Edge versions */
:-moz-placeholder {
  color: #a9a9a9;
}
