@import url(../../../../css/PsygenieCore.css);

.friend-list {
  max-height: 100%;
  overflow-y: auto;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.friend-card {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: default;
}

.friend-card:hover {
  transform: translateY(-3px) scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  border: 3px solid var(--primary-color);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.profile-pic:hover {
  transform: translateY(-3px) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.friend-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.friend-name {
  width: 20%;
}

.friend-name h3 {
  margin: 0;
  font-size: 1.2rem;
}

.friend-name p {
  margin: 5px 0;
  color: #555;
}

.friend-score,
.friend-personality {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.friend-score p,
.friend-personality p {
  color: #555;
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
}

.friend-score h1,
.friend-personality h1 {
  margin: 0;
}

.emotion-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 25%;
  justify-content: center;
}

.emotion-chip {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  font-size: 0.9rem;
  position: relative;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.emotion-chip:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.emotion-chip .tooltip {
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.emotion-chip .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.emotion-chip:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.5s;
}

.percentage-circle {
  display: inline-block;
  margin-left: 5px;
  border-radius: 20px;
  padding: 2px 6px;
  font-size: 0.8rem;
  color: #333;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  margin: 20px 0;
  color: #333;
  font-weight: bold;
}

.mycircle {
  margin: 0 auto;
  max-width: 90vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0; /* Adjusted for optimization */
  overflow: hidden;
}

.sort-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.personality-bars {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 25%;
}

.personality-bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.trait-label {
  text-align: left;
  margin-right: 10px;
  font-size: small;
}

.bar-container {
  width: 70%;
  border-radius: 10px;
  overflow: hidden;
  height: 10px;
}

.bar {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.percentage-label {
  text-align: right;
  margin-left: 10px;
  font-size: smaller;
}

.circular-progress {
  display: block;
  margin: auto;
}

.circle-background,
.circle-progress {
  fill: transparent;
  stroke-width: 5;
}

.circle-progress {
  stroke: var(--primary-color);
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s ease;
}

.circle-text {
  fill: #333;
  font-size: 1.5em;
  font-weight: bold;
}

.my-circle-main {
  position: relative;
}

.my-circle {
  padding: 20px;
  position: relative;
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sort-options select {
  padding: 5px 10px;
  border-radius: 5px;
}

.sort-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.pagination-button {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #4db6ac;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #3a9a8e;
}

.pagination-text {
  margin: 0 10px;
  font-weight: bold;
}

.pagination {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.my-circle {
  position: relative;
}

.sort-options-right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.sort-field-toggle,
.sort-order-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.sort-field-toggle span,
.sort-order-toggle span {
  font-size: 16px;
}

.friend-card-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the color of the parent element */
}

.friend-card-link:hover {
  /* Optional: Add hover effects if needed */
  text-decoration: none; /* Ensure no underline on hover */
  color: #000; /* Change color on hover if desired */
}
