/* UserCard.module.css */
.user-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 31px;
    text-align: center;
}

.profile-pic-large-make {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 16px;
}

.user-info h2 {
    font-size: 1.2em;
    font-weight: bold;
    margin: 8px 0;
}

.user-info p {
    font-size: 1em;
    color: gray;
    margin-bottom: 16px;
}

.user-score {
    display: flex;
    flex-direction: column;
    align-items: center;
}
